import auth0, { Auth0DecodedHash } from 'auth0-js';

import { setItem, removeItem, LocalStorageKey } from 'services/persistentData';
import routes from 'routes';

class Auth {
  auth0: auth0.WebAuth;
  accessToken?: string | null = null;
  expiresAt: number | null = null;

  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: window.config.AUTH0.DOMAIN || '',
      audience: window.config.AUTH0.AUDIENCE,
      clientID: window.config.AUTH0.CLIENT_ID || '',
      redirectUri: `${window.config.WEB_BASE_URL}${routes.callback()}`,
      responseType: 'token',
    });
  }

  getAccessToken() {
    return this.accessToken;
  }

  isAuthenticated = () => {
    if (!this.expiresAt) return false;
    else {
      return new Date().getTime() < this.expiresAt;
    }
  };

  signIn = () => this.auth0.authorize();

  /**
   * Gets called by the main Router
   */
  silentAuth = () => {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession(
        { domain: window.config.AUTH0.DOMAIN },
        (err, authResult) => {
          if (err) return reject(err);

          this.setSession(authResult).then(() => resolve(authResult));
        }
      );
    });
  };

  /**
   * This gets called from CallbackComponent when we come back
   * from auth0, with the tokens in the query.
   */
  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.accessToken) {
          return reject(err);
        }

        this.setSession(authResult).then(() => resolve(authResult));
      });
    });
  };

  protected async setSession(authResult: Auth0DecodedHash) {
    this.accessToken = authResult.accessToken;
    if (this.accessToken)
      setItem(LocalStorageKey.ACCESS_TOKEN, this.accessToken);
    this.expiresAt = new Date().getTime() + (authResult.expiresIn || 0) * 1000;
  }

  signOut = () => {
    removeItem(LocalStorageKey.ACCESS_TOKEN);
    this.auth0.logout({
      returnTo: window.config.WEB_BASE_URL,
      clientID: window.config.AUTH0.CLIENT_ID,
    });
  };
}

const auth0Client = new Auth();

export default auth0Client;
