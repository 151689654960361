import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IPropsText extends IPropsTextWrapper {
  children: ReactNode | string;
}
const Text = ({ children, ...rest }: IPropsText) => {
  return <TextWrapper {...rest}>{children}</TextWrapper>;
};

interface IPropsTextWrapper {
  color?:
    | 'normal'
    | 'important'
    | 'secondary'
    | 'error'
    | 'invert'
    | 'alternate'
    | 'tertiary';
  bold?: boolean;
  size?:
    | 'heading'
    | 'important'
    | 'caption'
    | 'quantity'
    | 'legend'
    | 'title'
    | 'normal'
    | 'big'
    | 'medium';
  italic?: boolean;
  font?: 'primary' | 'secondary';
  noWrap?: boolean;
}

const TextWrapper = styled.span<IPropsTextWrapper>`
  font-size: ${({ size = 'normal', theme }) => theme.font.size[size]};
  line-height: ${({ size = 'normal', theme }) => theme.font.lineHeight[size]};
  color: ${({ color = 'normal', theme }) => theme.colors.text[color]};
  font-weight: ${({ bold }) => (bold ? '600' : '300')};
  display: inline-flex;
  font-style: ${({ italic }) => (italic ? 'italic' : 'inherit')};
  font-family: ${({ font = 'primary', theme }) => theme.font.family[font]};
  white-space: ${({ noWrap = false }) => (noWrap ? 'nowrap' : 'inherit')};
`;

export default Text;
