import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Text from 'components/Text';
import { theme } from 'theme';

const Wrapper = styled.div`
  padding-left: 20px;
`;

const ErrorCode = styled.div`
  margin-bottom: 8px;
`;

interface IToastProps {
  errorCode?: number;
  errorMessage: string;
}

export const ErrorToast = ({ errorCode, errorMessage }: IToastProps) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {errorCode && (
          <ErrorCode>
            {t('error:toast.message.errorCode', { errorCode })}
          </ErrorCode>
        )}
        <Text color="error">{t(`error:message.${errorMessage}`)}</Text>
      </Wrapper>
    </ThemeProvider>
  );
};

const StyledButton = styled.button`
  background: none;
  border: 0px solid transparent;
  &:focus {
    outline: none;
  }
`;

export const CloseButton = ({ closeToast }: any) => (
  <ThemeProvider theme={theme}>
    <StyledButton onClick={closeToast}>x</StyledButton>
  </ThemeProvider>
);
