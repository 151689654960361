import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'components/Icons';

interface IProps {
  className?: string;
  justify?: 'center' | 'flex-start' | 'flex-end';
}

export default function Loader({ className, justify = 'center' }: IProps) {
  return (
    <StyledSpinner className={className} justify={justify}>
      <Spinner width={60} />
    </StyledSpinner>
  );
}

export const GlobalLoader = styled(Loader)`
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

interface IStyledSpinnerProps {
  justify: 'center' | 'flex-start' | 'flex-end';
}

const StyledSpinner = styled.div<IStyledSpinnerProps>`
  display: flex;
  justify-content: ${({ justify }) => justify};
  align-items: ${({ justify }) => justify};
  width: 100%;
`;
