import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { from, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { getItem, LocalStorageKey } from 'services/persistentData';
import errorHandler from './errors';

const httpLink = createHttpLink({
  uri: window.config.API_GRAPHQL_ENDPOINT,
});

const wsLink = new WebSocketLink({
  uri: window.config.API_GRAPHQL_ENDPOINT.replace(/^http(s)?/, 'ws$1'),
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = getItem(LocalStorageKey.ACCESS_TOKEN);
      return { authorization: token ? `Bearer ${token}` : '' };
    },
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getItem(LocalStorageKey.ACCESS_TOKEN);

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(errorHandler.onError);

const cache = new InMemoryCache();

cache.writeData({
  data: {},
});

export const client = new ApolloClient({
  link: from([
    errorLink,
    authLink,

    // Use Websockets for subscriptions, and HTTP otherwise.
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    ),
  ]),
  cache,
});
