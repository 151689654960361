import React from 'react';
import { ErrorResponse } from 'apollo-link-error';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { ErrorToast } from 'components/ErrorToast';
import { toast } from 'react-toastify';

const ignoreError = (graphQLError: GraphQLError) => {
  return graphQLError.path && graphQLError.path.includes('getTemporaryCode');
};

const onError = (error: ErrorResponse) => {
  if (error.graphQLErrors) {
    error.graphQLErrors.filter(error => !ignoreError(error)).forEach((graphQLError) => {
      switch (graphQLError.message) {
        case 'AUTH_REQUIRED':
        case 'EXPIRED_AUTH':
          console.log('graphQLError', graphQLError.message);
          window.location.href = '/login';
          break;
          case 'OUT_OF_STOCK':
            console.log('graphQLError', graphQLError.message);
            toast(<ErrorToast errorMessage="OUT_OF_STOCK" />);
            break;
        default:
          console.error('graphQLError', graphQLError);
          toast(<ErrorToast errorMessage="ERROR" />);
          break;
      }
    });
  }
};

export default { onError };
