import { DefaultTheme, createGlobalStyle } from 'styled-components';
import { Colors } from './styled';

export const colors: Colors = {
  error: '#F13F3D',
  none: 'transparent',
  primary: '#2196f3',
  secondary: '#2196f3',
  tertiary: '#61D57B',
  alternate: '#B9B9B9',
  lightPrimary: '#D4EBFD',
  lightSecondary: '',
  lightAlternate: '',
  lightColor: '',
  primaryInvert: 'white',
  secondaryInvert: 'white',
  alternateInvert: '',
  silver: '#E9EBEF',
  swordfish: '#F1F3F4',
  charcoal: '#242424',
  aluminium: '#6B6B6B',
  chalkboard: '#979797',
};

export const theme: DefaultTheme = {
  font: {
    family: {
      primary: '"Open Sans", Arial, Sans-Serif',
      secondary: 'Helvetica, Arial, Sans-Serif',
    },
    size: {
      heading: '26px',
      important: '36px',
      caption: '23px',
      quantity: '23px',
      legend: '20px',
      title: '32px',
      normal: '18px',
      medium: '20px',
      big: '100px',
    },
    lineHeight: {
      heading: '36px',
      important: '49px',
      quantity: '32px',
      caption: '32px',
      legend: '27px',
      title: '43px',
      normal: '24px',
      big: '105px',
      medium: '20px',
    },
  },
  colors: {
    palette: {
      ...colors,
    },
    status: {
      ready: '#61D57B',
      late: '#61D57B',
      outdated: '#B9B9B9',
      expired: colors.error,
      waiting: '#FF8000',
    },
    background: {
      main: colors.swordfish,
      card: 'white',
      header: 'white',
      primary: colors.tertiary,
      shadowbutton: colors.silver,
    },
    text: {
      normal: 'inherit',
      important: colors.primary,
      secondary: colors.alternate,
      error: colors.error,
      invert: colors.primaryInvert,
      alternate: colors.aluminium,
      legend: colors.chalkboard,
      tertiary: colors.tertiary,
    },
    actions: {
      primary: colors.primary,
      secondary: colors.secondary,
      alternate: colors.alternate,
    },
    border: {
      normal: colors.aluminium,
    },
  },
  dimensions: {
    actions: {
      primary: '4.75rem',
      secondary: '3rem',
      tertiary: '2rem',
    },
    badges: {
      xxsmall: '1.125rem',
      xsmall: '1.5rem',
      small: '2rem',
      medium: '3rem',
      big: '4.75rem',
    },
  },
  spacing: {
    card: '1.2rem',
  },
};

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');
  html { font-size: 16px; }
  * {
    box-sizing: border-box;
    outline: none;
  }
  body {
    font-family: ${theme.font.family};
    background-color: ${theme.colors.background.main};
    margin :0;
    padding: 0;
    display: flex;
    touch-action: manipulation;
  }
  #root {
    flex-direction: column;
    min-width: 100vw;
    min-height: 100vh;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }

  :focus {
    outline: none;
  }

  input:focus {
    outline: 1px solid ${theme.colors.palette.none};
  }

  input:disabled,
  button:disabled {
    cursor: not-allowed;
    opacity: 0.75;
}

  button {
    border: none;
    cursor: pointer;
  }

  button:active {
    border-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h1, h2, h3 {
    font-weight: normal;
    margin: 0px;
  }

  .app-toast-container {
    padding: 0px !important;
  }

  .app-toast {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px !important;
    background-color: ${theme.colors.background.main} !important;
    /* Toast are mainly used for error, let's stick with the error color for the moment */
    border-left: 8px solid ${theme.colors.palette.error};
    color: ${theme.colors.text.error};
  }
`;
